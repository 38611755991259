<template>
  <fw-layout back-to="/" :loading="loading" :main-sidebar="false" loading-title="Apoio S+">
    <template #main-content>
      <div class="flex gap-10">
        <div class="flex-1">
          <div>
            <fw-heading size="h2" muted>O seu agendamento</fw-heading>
            <div class="flex gap-1">
              <div>
                <fw-heading size="h1">Consulta de Medicina no Trabalho</fw-heading>
              </div>
              <div class="flex-shrink-0">
                <fw-tag type="light-border-box" size="xs" class="m-2"
                  >{{ appointment.prefix }}{{ appointment.code }}</fw-tag
                >
              </div>
            </div>
          </div>
          <div>
            <PanelReservationSlots
              class="mt-10 mb-5"
              :saving-data.sync="savingData"
              :reservation.sync="reservation"
              :appointment="appointment"
              :slots="slots"
              :can-edit="checks.canEdit"
              @open-slots="openModal"
            />
          </div>
        </div>
        <div v-if="reservation?.key" class="w-64 flex flex-col gap-4">
          <div>
            <fw-label>Estado do agendamento</fw-label>
            <div>
              <fw-tag size="lg" expanded :type="reservationStates[reservation.state].color">
                {{ reservationStates[reservation.state].label[language] }}
              </fw-tag>
            </div>
          </div>
          <div v-if="reservation.state === 'submitted' && reservation?.time_slot?.key" class="flex flex-col gap-1">
            <fw-label marginless>Data da consulta</fw-label>
            <div class="text-2xl font-semibold flex gap-2 items-center">
              <div>
                <fw-icon-calendar-event v-if="reservation.state === 'submitted'" class="w-7 h-7 text-primary" />
                <fw-icon-calendar v-else class="w-7 h-7 text-gray-500" />
              </div>
              <div>
                {{ reservation?.time_slot?.start_datetime | formatDateTime }}
              </div>
            </div>
          </div>
          <div class="flex gap-4">
            <div class="w-1/2">
              <fw-button
                v-if="checks.canEdit"
                ref="edit-button"
                expanded
                type="border-primary"
                :disabled="savingData"
                @click.native="openModal"
              >
                Reagendar
              </fw-button>
            </div>
            <div class="w-1/2">
              <fw-button
                v-if="checks.canCancel"
                ref="cancel-button"
                expanded
                type="border-light"
                :disabled="savingData"
                @click.native="cancelReservation"
              >
                Cancelar
              </fw-button>
            </div>
          </div>
          <div
            class="flex flex-col gap-4 text-sm mt-5 pt-5 font-medium text-gray-500 border-t opacity-70 hover:opacity-90"
          >
            <div>
              <fw-label marginless>Chave</fw-label>
              <div>{{ reservation.key }}</div>
            </div>
            <div>
              <fw-label marginless>Data de criação do agendamento</fw-label>
              <div>{{ reservation.created_date | formatDateTime }}</div>
            </div>
            <div v-if="reservation.submitted_date">
              <fw-label marginless>Data de submissão do agendamento</fw-label>
              <div class="flex items-center gap-1">
                <span>{{ reservation.submitted_date | formatDateTime }}</span>
                <fw-icon-checkbox-circle class="w-5 h-5 text-primary" />
              </div>
            </div>
            <div>
              <fw-label marginless>Número mecanográfico</fw-label>
              <div>
                {{
                  users && reservation && users[reservation.user_key] && users[reservation.user_key].number
                    ? users[reservation.user_key].number
                    : 'Não existente'
                }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <PanelReservationMessages
        v-if="reservation?.key"
        class="my-5"
        :saving-data.sync="savingData"
        :reservation.sync="reservation"
        :appointment="appointment"
        :checks="checks"
        @added-message="getReservation"
      />

      <fw-modal
        v-if="isActiveSlotsModal"
        :active.sync="isActiveSlotsModal"
        boxed="sm"
        size="min"
        width="42rem"
        @close="closeModal"
      >
        <PanelSlots
          v-if="isActiveSlotsModal"
          :slots="slots"
          :slot-key.sync="slotKey"
          @save="confirmNewSlot"
          @close="closeModal"
        ></PanelSlots>
      </fw-modal>

      <fw-panel-info debug label="Data (raw)">
        <json-viewer :value="{ appointment, reservation, slots, validations }"></json-viewer>
      </fw-panel-info>
    </template>
  </fw-layout>
</template>

<script>
import { RESERVATION_STATES } from '@/utils/index.js'
import PanelReservationMessages from '@/components/panels/occupationalMedicine/PanelReservationMessages'
import PanelReservationSlots from '@/components/panels/occupationalMedicine/PanelReservationSlots'
import utils from '@/fw-modules/fw-core-vue/utilities/utils'
import Dates from '@/fw-modules/fw-core-vue/utilities/dates'
import groupBy from 'lodash/groupBy'
import PanelSlots from '@/components/panels/occupationalMedicine/PanelSlots'

export default {
  components: {
    PanelReservationMessages,
    PanelReservationSlots,
    PanelSlots,
  },

  data() {
    return {
      isActiveSlotsModal: false,
      slotKey: null,
      loading: true,
      savingData: false,
      initialLoading: true,
      appointment: {},
      slots: {},
      reservation: {
        slot_key: null,
      },
      validations: {},
      reservationStates: RESERVATION_STATES,
    }
  },

  computed: {
    api() {
      return this.$store.state.api.base
    },

    user() {
      return this.$store.getters.getUser
    },

    language() {
      return this.$store.state.language
    },

    appointmentKey() {
      return this.$route.params.key
    },

    checks() {
      return {
        isSubmitted: this.reservation?.state == 'submitted',
        isCanceled: this.reservation?.state == 'canceled',
        isDraft: this.reservation?.state == 'draft',
        canCancel: this.validations?.can_cancel,
        canEdit: this.reservation?.key ? this.validations?.can_edit : true,
      }
    },

    activeReservationSlotKey() {
      return this.reservation?.state == 'submitted' ? this.reservation?.slot_key : null
    },
  },

  async mounted() {
    this.loading = true
    await Promise.all([this.getAppointment(), this.getAppointmentSlots()])
    this.loading = false
  },

  methods: {
    async getAppointment() {
      this.loading = true
      try {
        const response = await this.api.getAppointment(this.appointmentKey)
        console.log('getAppointment :>> ', response)
        this.appointment = response
        if (response.reservation) {
          this.reservation = response.reservation
          this.getReservation()
        }
      } catch (error) {
        console.log('Error getAppointment :>> ', error)
      } finally {
        this.loading = false
      }
    },

    async createReservation(newSlotKey) {
      console.log('startReservation :>> ')
      if (this.activeReservationSlotKey == newSlotKey) return

      this.loading = true

      try {
        const response = await this.api.createReservation(this.appointmentKey, { time_slot: newSlotKey })
        console.log('createReservation :>> ', response)
        await Promise.all([this.getAppointment(), this.getAppointmentSlots()])
      } catch (error) {
        console.log('Error createReservation :>> ', error)
        const errorKey = utils.errors(error).getKey()
        if (errorKey === 'SlotUnavailable') {
          this.$buefy.dialog.alert({
            title: 'Ocorreu um erro',
            message: `<div class="mb-3">O Horário selecionado já não está disponivel.</div>`,
            type: 'is-danger',
          })
        } else {
          this.$buefy.snackbar.open({
            message: 'Ocorreu um erro ao tentar fazer o agendamento.',
            type: 'is-danger',
          })
        }
      }

      this.loading = false
    },

    async getReservation() {
      this.loading = true

      try {
        const response = await this.api.getReservation(this.appointmentKey, this.reservation.key)
        console.log('getReservation response :>>', response)
        this.reservation = response.reservation
        this.appointment = response.appointment
        this.validations = response.validations
        this.users = response.users
        this.newSlotKey = response.reservation.slot_key
      } catch (error) {
        console.log('Error getReservation :>> ', error)
      }

      this.loading = false
    },

    async saveReservation(type = null, reservation = null) {
      this.savingData = true
      if (!reservation) reservation = this.reservation
      console.log('Save reservation: ', reservation)

      let tmpReservation = {}

      if (type === 'submit') {
        tmpReservation['state'] = 'submitted'
      } else if (type === 'cancel') {
        tmpReservation['state'] = 'canceled'
      }

      utils.tryAndCatch(this, async () => {
        const response = await this.api.updateReservation(this.appointmentKey, this.reservation.key, tmpReservation)
        console.log('Got response: ', response)
        this.reservation = response.reservation
        if (response.validations) this.validations = response.validations

        this.$buefy.snackbar.open({
          message: 'Os dados foram guardados.',
          type: 'is-white',
          position: 'is-bottom-right',
          indefinite: false,
          duration: 2000,
          queue: false,
        })

        if (type === 'cancel') this.getAppointmentSlots()
      })

      this.savingData = false
    },

    cancelReservation() {
      this.$buefy.dialog.confirm({
        type: 'is-black',
        title: 'Tem a certeza que deseja cancelar o agendamento?',
        message: 'Se cancelar, poderá fazer novo agendamento desde que as marcações estejam abertas.',
        confirmText: 'Cancelar agendamento',
        cancelText: 'Fechar',
        onConfirm: () => {
          this.saveReservation('cancel')
        },
      })
    },

    async getAppointmentSlots() {
      this.loading = true
      try {
        const response = await this.api.getAppointmentSlots(this.appointmentKey)
        console.log('getAppointmentSlots :>> ', response)
        this.slots = groupBy(response.slots, x => Dates.buildCore(x.start_datetime).format('YYYY-MM-DD'))
      } catch (error) {
        console.log('Error getAppointmentSlots :>> ', error)
      }
      this.loading = false
    },

    openModal() {
      this.slotKey = this.activeReservationSlotKey
      this.isActiveSlotsModal = true
    },

    closeModal() {
      this.isActiveSlotsModal = false
      this.slotKey = null
    },

    confirmNewSlot() {
      if (!this.slotKey) {
        this.isActiveSlotsModal = false
      }

      if (this.reservation?.reservations?.length || this.reservation?.time_slot) {
        this.$buefy.dialog.confirm({
          type: 'is-black',
          title: 'Tem a certeza que deseja efetuar o agendamento?',
          message: 'Ao efetuar este agendamento o anterior será cancelado.',
          confirmText: 'Confirmar',
          cancelText: 'Fechar',
          onConfirm: () => {
            this.createReservation(this.slotKey)
            this.isActiveSlotsModal = false
          },
        })
      } else {
        this.$buefy.dialog.confirm({
          type: 'is-black',
          title: 'Tem a certeza que deseja efetuar o agendamento?',
          confirmText: 'Confirmar',
          cancelText: 'Fechar',
          onConfirm: () => {
            this.createReservation(this.slotKey)
            this.isActiveSlotsModal = false
          },
        })
      }
    },
  },
}
</script>

<i18n>
{
  "pt": {
    "appointmentType": {
      "scholarship": "Apoio",
      "occupational_medicine": "Medicina do Trabalho"
    },
    "notDefined": "Não definido",
    "prefix": "Prefixo",
    "createdAt": "Criado em",
    "startsAt": "Início das candidaturas",
    "endsAt": "Fim das candidaturas",
    "publishedOn": "Publicado em",
    "notFound": "Procedimento não encontrado"
  },
  "en": {
    "appointmentType": {
      "scholarship": "Scholarship",
      "occupational_medicine": "Occupational Medicine"
    },
    "notDefined": "Not defined",
    "prefix": "Prefix",
    "createdAt": "Created at",
    "startsAt": "Reservations Start Date",
    "endsAt": "Reservations End Date",
    "publishedOn": "Published on",
    "notFound": "appointment not found"
  }
}
</i18n>
