<template>
  <fw-panel v-if="!checks.isDraft && !checks.isCanceled" title="Mensagens" :counter="messages.length" boxed="sm">
    <PanelMessages
      :add-message-button-type="'primary'"
      add-message-modal-instructions="Após enviar a mensagem, a mesma não poderá ser editada ou eliminada."
      :users="messagesUsers"
      :messages="messages"
      :pagination="paginationMessages"
      :loading="loadingMessages"
      :show-public-private-toggle="false"
      :can-add-message="checks.canSendMessages"
      :default-add-message-is-private="false"
      :ignore-confirm-public-message="true"
      class="h-full"
      @page-changed="messagesPageChanged"
      @add-message="addMessage"
      @update-message="updateMessage"
      @delete-message="deleteMessage"
    />
  </fw-panel>
</template>

<script>
import PanelMessages from '@/fw-modules/fw-core-vue/ui/components/panels/PanelMessages'
import { RESERVATION_STATES } from '@/utils/index.js'

export default {
  components: {
    PanelMessages,
  },

  props: {
    checks: {
      type: Object,
      default: () => {
        return {}
      },
    },

    reservation: {
      type: Object,
      default: () => {
        return {}
      },
    },

    appointment: {
      type: Object,
      default: () => {
        return {}
      },
    },

    savingData: {
      type: Boolean,
    },
  },

  data() {
    return {
      loadingMessages: false,
      messages: [],
      messagesUsers: {},
      paginationMessages: {
        // TODO:
        page: 1,
        totalResults: 0,
        totalPages: 1,
        limit: 50,
      },
      reservationStates: RESERVATION_STATES,
      subStates: ['in_analysis_waiting', 'complaint', 'complaint_analysis'],
    }
  },

  computed: {
    api() {
      return this.$store.state.api.base
    },
    user() {
      return this.$store.getters.getUser
    },
    language() {
      return this.$store.state.language
    },
  },

  mounted() {
    this.getMessages()
  },

  methods: {
    async getMessages() {
      this.loadingMessages = true

      try {
        console.log('getMessages params :>> ', this.paginationMessages)
        const response = await this.api.getReservationMessages(this.appointment.key, this.reservation.key, {
          page: this.paginationMessages.page,
          limit: this.paginationMessages.limit,
        })
        console.log('getMessages :>> ', response)
        this.messages = response.messages
        this.messagesUsers = { ...this.messagesUsers, ...response.users }
        this.paginationMessages = {
          limit: response.pagination.active_limit,
          page: response.pagination.current_page,
          totalResults: response.pagination.total_items,
          totalPages: response.pagination.total_pages,
        }
      } catch (e) {
        console.log('Error getMessages :>> ', e)
      }

      this.loadingMessages = false
    },
    async addMessage(messageData) {
      console.log('messageData :>> ', messageData)
      try {
        const response = await this.api.addReservationMessages(this.appointment.key, this.reservation.key, messageData)
        console.log('response :>> ', response)
        console.log('addedMessage :>> ', response)
        this.messages.unshift(response.message)
        this.$emit('added-message', response)
      } catch (error) {
        console.log('error :>> ', error)
        this.savingMessageError = true
        this.$buefy.snackbar.open({
          message: this.$t('errorOccuredSavingMessage'),
          type: 'is-warning',
          position: 'is-top-right',
          indefinite: true,
          duration: 2000,
          queue: false,
        })
      }
    },
    async updateMessage(messageData) {
      try {
        const response = await this.api.updateReservationMessage(
          this.appointment.key,
          this.reservation.key,
          messageData.key,
          messageData
        )
        console.log('updatedMessage :>> ', response)
        let index = this.messages.findIndex(el => el.key == response.key)
        if (index > -1) this.$set(this.messages, index, response)
      } catch (error) {
        console.log('error :>> ', error)
        this.$buefy.snackbar.open({
          message: this.$t('errorOccuredSavingMessage'),
          type: 'is-warning',
          position: 'is-top-right',
          indefinite: true,
          duration: 2000,
          queue: false,
        })
      }
    },
    deleteMessage(messageKey) {
      this.messages = this.messages.filter(el => el.key != messageKey)
    },
    messagesPageChanged(page) {
      console.log('messagesPageChanged :>> ', page)
      this.paginationMessages.page = page
      this.getMessages()
    },
  },
}
</script>

<i18n>
{
  "pt": {
    "statusMainBoardTitle": "Estado",
    "key": "Chave",
    "createdAt": "Criado em",
    "submittedAt": "Publicado em",
    "notDefined": "Não definido",
    "appointmentType": {
      "scholarship": "Apoio",
      "occupational_medicine": "Medicina do Trabalho"
    },
    "decision": "Decisão",
    "decisionType": {
      "accepted": "Aceite",
      "rejected": "Rejeitada"
    },
    "phaseFinished": "Terminado",
    "phaseOngoing": "A decorrer",
    "phasePending": "Pendente",
    "phaseClosed": "Processo terminado",
    "reply": "Responder",
    "addComplaint": "Apresentar pronúncia"
  },
  "en": {
    "statusMainBoardTitle": "Estado",
    "key": "Chave",
    "createdAt": "Criado em",
    "submittedAt": "Publicado em",
    "notDefined": "Não definido",
    "decision": "Decisão",
    "decisionType": {
      "accepted": "Aceite",
      "rejected": "Rejeitada"
    },
    "appointmentType": {
      "scholarship": "Apoio",
      "occupational_medicine": "Occupational Medicine"
    },
    "phaseFinished": "Terminado",
    "phaseOngoing": "A decorrer",
    "phasePending": "Pendente",
    "phaseClosed": "Processo terminado",
    "reply": "Responder",
    "addComplaint": "Apresentar pronúncia"
  }
}
</i18n>
