<template>
  <section>
    <div
      v-if="!reservation?.key"
      class="bg-white rounded-lg flex flex-col text-center items-center juc-text-color font-semibold gap-7 py-7"
    >
      <div class="text-sm">
        Ainda não agendou a sua consulta. Para o fazer, carregue no botão seguinte e escolha um horário disponível.
      </div>
      <fw-button type="primary" @click.native="$emit('open-slots')">Agendar consulta</fw-button>
    </div>

    <fw-panel v-if="reservation.key" :title="'Datas reservadas'" boxed="xs">
      <div
        v-if="reservation.time_slot && reservation.state == 'submitted'"
        class="bg-white rounded-xl px-4 py-3 flex flex-col gap-2"
      >
        <div class="inline-flex items-center justify-between w-full" role="button">
          <div class="font-semibold flex-1">
            <div class="text-lg">{{ reservation.time_slot.start_datetime | formatDate }}</div>
            <div class="text-sm text-gray-500">
              {{ $t(`weekdays.${getWeekDay(reservation.time_slot.start_datetime)}`) }}
            </div>
          </div>
        </div>

        <RecordSlot
          :time-slot="reservation.time_slot"
          :active="true"
          class="mb-2"
          :show-actions="false"
          :show-availability="false"
        >
          <div>Reservado</div>
        </RecordSlot>
      </div>

      <b-collapse
        v-for="(daySlots, day) in cancelledSlots"
        :key="day"
        class="bg-white rounded-xl px-4 py-3 flex flex-col gap-2 mt-3"
        :aria-id="day"
      >
        <template #trigger="props">
          <div
            class="inline-flex items-center justify-between w-full"
            role="button"
            :aria-controls="day"
            :aria-expanded="props.open"
          >
            <div class="font-semibold flex-1">
              <div class="text-lg">{{ day | formatDate }}</div>
              <div class="text-sm text-gray-500">
                {{ $t(`weekdays.${getWeekDay(day)}`) }}
              </div>
            </div>

            <div class="w-5 h-5">
              <fw-icon-chevron-up v-if="props.open" class="w-5 h-5" />
              <fw-icon-chevron-down v-else class="w-5 h-5 " />
            </div>
          </div>
        </template>

        <div class="w-full flex flex-col gap-2">
          <RecordSlot
            v-for="(slot, slotI) in daySlots"
            :key="slotI"
            :time-slot="slot"
            :show-actions="false"
            :show-availability="false"
          >
            <div>
              Cancelado
              <!-- {{ slot.canceled_by === loggedUser.key ? 'por si' : 'pelo gestor' }} -->
            </div>
          </RecordSlot>
        </div>
      </b-collapse>

      <fw-panel-info v-if="!reservation.time_slot && !reservation?.reservations?.length" clean
        >Ainda não foram reservados slots .</fw-panel-info
      >
    </fw-panel>
  </section>
</template>

<script>
import RecordSlot from '@/components/records/RecordSlot'
import Dates from '@/fw-modules/fw-core-vue/utilities/dates'
import groupBy from 'lodash/groupBy'

export default {
  components: {
    RecordSlot,
  },

  props: {
    reservation: {
      type: Object,
      default: () => {
        return {
          slot_key: null,
        }
      },
    },

    appointment: {
      type: Object,
      default: () => {
        return {}
      },
    },

    savingData: {
      type: Boolean,
    },

    slots: {
      type: Object,
      default: () => {},
    },

    loading: {
      type: Boolean,
      default: false,
    },

    canEdit: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {}
  },

  computed: {
    api() {
      return this.$store.state.api.base
    },
    language() {
      return this.$store.state.language
    },
    loggedUser() {
      return this.$store.getters.getUser
    },
    secondaryLanguage() {
      return this.language === 'pt' ? 'en' : 'pt'
    },
    langs() {
      return [this.language, this.secondaryLanguage]
    },

    cancelledSlots() {
      if (!this.reservation?.reservations) return []
      const slots = this.reservation.reservations.map(el => {
        return { ...el.time_slot, canceled_by: el.canceled_by, user_key: el.user_key }
      })

      return groupBy(slots, x => Dates.buildCore(x.start_datetime).format('YYYY-MM-DD'))
    },
  },

  methods: {
    getWeekDay(date) {
      return Dates.buildCore(date).day()
    },
  },
}
</script>

<i18n>
{
  "pt": {
    "weekdays": {
      "0": "Domingo",
      "1": "Segunda-feira",
      "2": "Terça-feira",
      "3": "Quarta-feira",
      "4": "Quinta-feira",
      "5": "Sexta-feira",
      "6": "Sábado"
    }
  },
  "en": {
    "weekdays": {
      "0": "Sunday",
      "1": "Monday",
      "2": "Tuesday",
      "3": "Wednesday",
      "4": "Thursday",
      "5": "Friday",
      "6": "Saturday"
    }
  }
}
</i18n>
